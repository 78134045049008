<template>
  <div class="wrap">
    <div class="facilityBox">
      <el-breadcrumb separator="/" style="margin: 10px 0">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          >{{ !pageTitle ? "审核" : pageTitle }}代理商</el-breadcrumb-item
        >
        <el-breadcrumb-item>代理商信息</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 表单区域 -->
      <el-form label-width="195px" :inline="true" disabled>
        <div class="title">代理商认证信息</div>
        <div class="content content1">
          <div class="upBox">
            <el-image
              class="img"
              :src="confirmInfo.LegalPersonIDCardOnURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">法人身份证(正面)</div>
          </div>
          <div class="upBox">
            <el-image
              class="img"
              :src="confirmInfo.LegalPersonIDCardBackURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">法人身份证(反面)</div>
          </div>
          <div class="upBox" v-if="confirmInfo.CFOIDCardUrl">
            <el-image
              class="img"
              :src="confirmInfo.CFOIDCardUrl"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">财务经办人身份证(正面)</div>
          </div>
          <div class="upBox" v-if="confirmInfo.CFOIDCardBackUrl">
            <el-image
              class="img"
              :src="confirmInfo.CFOIDCardBackUrl"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">财务经办人身份证(反面)</div>
          </div>
          <div class="upBox" v-if="confirmInfo.BusinessLicenseURL">
            <el-image
              class="img"
              :src="confirmInfo.BusinessLicenseURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">营业执照(原件三合一正本)</div>
          </div>
          <div class="upBox" v-if="confirmInfo.BankCardUrl">
            <el-image
              class="img"
              :src="confirmInfo.BankCardUrl"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">企业银行卡</div>
          </div>
          <div class="upBox" v-if="confirmInfo.OfficialSealUrl">
            <el-image
              class="img"
              :src="confirmInfo.OfficialSealUrl"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">企业印章</div>
          </div>
          <div class="upBox" v-if="confirmInfo.RTBusinessLicenseURL">
            <el-image
              class="img"
              :src="confirmInfo.RTBusinessLicenseURL"
              :preview-src-list="srcList"
            >
            </el-image>
            <div class="upText">许可与资源</div>
          </div>
          <div class="upBox" v-if="eInfoEx">
            <img
              class="upDiv"
              @click="uploadInvoiceDialog = true"
              src="@/assets/image/upload.png"
            />
            <div class="upText">其他证件</div>
          </div>
        </div>

        <div class="title">代理商基本信息</div>
        <div class="content">
          <el-form-item label="法人姓名" prop="LegalPersonIName">
            <el-input
              v-model="basicInfoForm.LegalPersonIName"
              placeholder="法人姓名"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="法人身份证号" prop="LegalPersonIIDCard">
            <el-input
              v-model="basicInfoForm.LegalPersonIIDCard"
              placeholder="法人身份证号"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="法人手机号码" prop="LegalPersonlPhone">
            <el-input
              v-model="basicInfoForm.LegalPersonlPhone"
              placeholder="法人手机号码"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="法人身份证起始日期"
            prop="LegalPersonlIDCardDate"
          >
            <el-date-picker
              v-model="basicInfoForm.LegalPersonlIDCardDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="法人身份证起始日期"
              style="width: 200px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="法人身份证有效期" prop="LegalPersonlIDCardDate">
            <el-date-picker
              v-model="basicInfoForm.LegalPersonlIDCardDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="法人身份证有效期"
              style="width: 200px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="法人银行卡号" prop="LegalPersonBankCardNo">
            <el-input
              v-model="ebaseInfoForm.LegalPersonBankCardNo"
              placeholder="法人银行卡号"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务经办人姓名" prop="CFOName">
            <el-input
              v-model="ebaseInfoForm.CFOName"
              placeholder="经办人姓名"
              onkeyup="value=value.replace(/[\d]/g,'') "
              onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
              maxlength="15"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="财务经办人身份证号" prop="CFOIDCardNo">
            <el-input
              v-model="ebaseInfoForm.CFOIDCardNo"
              placeholder="财务经办人身份证号"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="财务经办人手机号码" prop="CFOPhone">
            <el-input
              v-model="ebaseInfoForm.CFOPhone"
              maxlength="11"
              placeholder="财务经办人手机号码"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="财务经办人身份证起始日期"
            prop="CFOIDCardStartDate"
          >
            <el-date-picker
              style="width: 200px"
              v-model="ebaseInfoForm.CFOIDCardStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="财务经办人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="财务经办人身份证有效期"
            prop="CFOIDCardValidDate"
          >
            <el-date-picker
              style="width: 200px"
              v-model="ebaseInfoForm.CFOIDCardValidDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="财务经办人身份证有效期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="企业性质" prop="BenefitWay">
            <el-input
              v-model="ebaseInfoForm.BenefitWayName"
              placeholder="企业性质"
              style="width: 200px"
            ></el-input>
          </el-form-item>
        </div>
        <div class="title">代理商企业信息</div>
        <div class="content">
          <el-form-item label="企业名称" prop="EnterpriseFullName">
            <el-input
              v-model="basicInfoForm.EnterpriseFullName"
              placeholder="企业名称"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="EnterpriseCreditCode">
            <el-input
              v-model="basicInfoForm.EnterpriseCreditCode"
              placeholder="统一社会信用代码"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="营业执照注册日期" prop="EnterpriseRegisterDate">
            <el-date-picker
              v-model="basicInfoForm.EnterpriseRegisterDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="营业执照注册日期"
              style="width: 200px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="营业执照有效日期" prop="EnterpriseEffectiveDate">
            <el-date-picker
              v-model="basicInfoForm.EnterpriseEffectiveDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="营业执照有效日期"
              style="width: 200px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="法定代表人" prop="LegalPersonIName">
            <el-input
              v-model="basicInfoForm.LegalPersonIName"
              placeholder="法定代表人"
            ></el-input>
          </el-form-item>
          <el-form-item label="营业执照住所" prop="EnterpriseAddress">
            <el-input
              v-model="basicInfoForm.EnterpriseAddress"
              placeholder="营业执照住所"
            ></el-input>
          </el-form-item>
          <el-form-item label="注册国家" prop="RegisterNationName">
            <el-input
              v-model="ebaseInfoForm.RegisterNationName"
              placeholder="注册国家"
            ></el-input>
          </el-form-item>
          <el-form-item label="境内/境外" prop="DomesticOverseasName">
            <el-input
              v-model="ebaseInfoForm.DomesticOverseasName"
              placeholder="境内/境外"
            ></el-input>
          </el-form-item>
          <el-form-item label="注册资本" prop="RegisteredCapital">
            <el-input
              v-model="basicInfoForm.RegisteredCapital"
              placeholder="注册资本"
            ></el-input>
          </el-form-item>
          <el-form-item label="税务登记号" prop="TAXID">
            <el-input
              v-model="basicInfoForm.TAXID"
              placeholder="税务登记号"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业行业分类" prop="tcDdat">
            <el-input
              v-model="basicInfoForm.tcDdat"
              placeholder="企业行业分类"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业所在省市区" prop="provinces">
            <el-input
              v-model="basicInfoForm.provinces"
              placeholder="企业所在省市区"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业所在道路门牌" prop="Road">
            <el-input
              v-model="basicInfoForm.Road"
              placeholder="企业所在道路门牌"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="经营范围" prop="BusinessScope">
            <el-input
              v-model="basicInfoForm.BusinessScope"
              placeholder="经营范围"
            ></el-input>
          </el-form-item>
        </div>
        <div class="title">代理商发票抬头</div>
        <div style="width: 100%; display: flex">
          <div style="width: 55%; display: flex">
            <div style="width: 50%">
              <el-form-item label="发票抬头企业全名" prop="invoiceTitleName">
                <el-input
                  v-model="vehicleForm.InvoiceTitleName"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="纳税人识别号" prop="invoiceTitleCreditCode">
                <el-input
                  v-model="vehicleForm.InvoiceTitleCreditCode"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业联系电话" prop="InvoiceTitlePhone">
                <el-input
                  v-model="vehicleForm.InvoiceTitlePhone"
                  placeholder="企业联系电话"
                ></el-input>
              </el-form-item>
              <el-form-item label="开户行所在地" prop="bankAddress.area">
                <el-input
                  :value="
                    vehicleForm.BankProvince +
                    vehicleForm.BankCity +
                    vehicleForm.BankArea
                  "
                  placeholder="开户行所在地"
                ></el-input>
              </el-form-item>
            </div>
            <div style="width: 50%">
              <el-form-item label="企业联系地址" prop="invoiceTitleAddress">
                <el-input
                  v-model="vehicleForm.InvoiceTitleAddress"
                  style="width: 200px"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="银行账户"
                prop="invoiceTitleBankerNumber"
              >
                <el-input
                  v-model="vehicleForm.InvoiceTitleBankerNumber"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="银行账户开户行"
                prop="invoiceTitleBankerName"
              >
                <el-input
                  v-model="vehicleForm.InvoiceTitleBankerName"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div
            class="upBox"
            style="margin-left: 10%; width: 278px; text-align: center"
          >
            <UploadImg
              ref="accountLicence"
              :loading="accountLicence.loading"
              :uploaded="accountLicence.uploaded"
              :picUrl="confirmInfo.AccountLicenceUrl"
            >
            </UploadImg>
            <div class="upText">开户证明</div>
          </div>
        </div>
        <!-- 基本存款账户 -->
        <div class="title">基本存款账户</div>
        <el-form-item label="基本存款账户账户名称" prop="BDAName">
          <el-input
            v-model="ebaseInfoForm.BDAName"
            placeholder="基本存款账户账户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户账户号码" prop="BDANo">
          <el-input
            v-model="ebaseInfoForm.BDANo"
            placeholder="基本存款账户账户号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户开户行" prop="BDABankName">
          <el-input
            v-model="ebaseInfoForm.BDABankName"
            placeholder="基本存款账户开户行"
          ></el-input>
        </el-form-item>
        <el-form-item label="基本存款账户编号" prop="BDANumber">
          <el-input
            v-model="ebaseInfoForm.BDANumber"
            placeholder="基本存款账户编号"
          ></el-input>
        </el-form-item>
        <!-- 附件信息 -->
        <div class="title">附件信息</div>
        <div style="margin: 0 0 20px 0">
          <div class="accessory">
            此处可用于上传各类文件：包括授权书、财务凭证、项目验收报告、公司证明、统计报表等。
          </div>
          <img
            style="cursor: pointer"
            @click="flag.isExchange = true"
            src="@/assets/image/upFlie.png"
          />
        </div>
      </el-form>
      <div class="btnWrap">
        <el-button size="medium" @click="routerBack()" icon="el-icon-arrow-left"
          >返回</el-button
        >
        <template v-if="isWaitPage">
          <el-button
            type="danger"
            @click="rejectModel = true"
            v-if="basicInfoForm.Status != 2"
            icon="el-icon-close"
          >
            拒绝通过
          </el-button>
          <el-button
            type="success"
            @click="isDialog = true"
            v-if="basicInfoForm.Status != 2"
            icon="el-icon-check"
          >
            认证通过，指派客服
          </el-button>
        </template>
      </div>
      <!-- 上传附件 -->
      <el-dialog
        class="addDialog"
        :visible.sync="flag.isExchange"
        width="1000px"
      >
        <span slot="title" class="dialog-title"> 上传附件 </span>
        <upLoadAccessory
          :pactlist="pactlist"
          :pactData="pactData"
          :pactLoading="pactLoading"
          :isAccessory="flag.isAccessory"
          @fileUpload="111"
          @delPact="111"
          @pactChange="pactChange"
        >
        </upLoadAccessory>
      </el-dialog>
      <el-dialog :visible.sync="isDialog" width="550px" class="deep_dialog">
        <span slot="title">指派客服</span>
        <div style="margin: 10px 0px">
          <el-input
            type="textarea"
            :rows="3"
            maxlength="100"
            show-word-limit
            placeholder="请输入备注。"
            v-model="agreeValue"
          >
          </el-input>
        </div>
        <el-alert
          style="margin-bottom: 10px"
          type="warning"
          :closable="false"
          title="温馨提示:请选择有客服能力的角色,挑选一名工作人员,将当前企业(代理商)指派给她"
        >
        </el-alert>
        <div>
          <el-input
            clearable
            @clear="clearInp()"
            placeholder="请输入客服名称"
            v-model="ChineseName"
            class="input-with-select"
            @keyup.enter.native="searchClick"
          >
            <el-select
              v-model="select"
              @change="selectlist()"
              slot="prepend"
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in roleslist"
                :key="item.ID"
                :label="item.Name"
                :value="item.ID"
              >
              </el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchClick()"
            >
            </el-button>
          </el-input>
          <div style="margin-top: 10px">
            <el-table
              :data="tableData"
              style="width: 100%"
              :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
            >
              <el-table-column align="center" width="55">
                <template slot-scope="scope">
                  <el-radio v-model="ChineseRadio" :label="scope.row.index">
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column
                label="姓名"
                align="center"
                prop="ChineseName"
              ></el-table-column>
              <el-table-column
                prop="Phone"
                align="center"
                label="电话号码"
                width="120"
              ></el-table-column>
              <el-table-column
                prop="TaskCnt"
                align="center"
                label="已派单数量"
              ></el-table-column>
            </el-table>
            <div style="margin-top: 10px">
              <!-- 分页 -->
              <el-pagination
                background
                class="pagination"
                @current-change="handleCurrentChange"
                :current-page.sync="pagination.page"
                :page-size="pagination.pagesize"
                layout="total, prev, pager, next, jumper"
                :total="pagination.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isDialog = false">取 消</el-button>
          <el-button type="primary" @click="UserByAssign()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 驳回弹窗 -->
      <el-dialog
        title="驳回意见"
        :visible.sync="rejectModel"
        width="30%"
        center
      >
        <span
          >驳回 {{ basicInfoForm.LegalPersonIName }}(代理商) 的提交申请：</span
        >
        <div style="margin: 20px 0 0 0">
          <el-input
            type="textarea"
            :rows="6"
            maxlength="100"
            show-word-limit
            placeholder="请输入驳回处理意见，限制100字。"
            v-model="rejectValue"
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="rejectModel = false">取 消</el-button>
          <el-button type="primary" @click="refuseClick(2)">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 其他证件dialog -->
      <el-dialog
        class="uploadImgDialog"
        title="上传其他证件(最多10份)"
        :visible.sync="uploadInvoiceDialog"
        width="1000px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <!-- 其他证件 -->
        <div class="uploadImgWrap">
          <div class="upBox" v-for="(item, index) in eInfoEx" :key="index">
            <el-image
              class="img"
              :src="item.ImageURL"
              :preview-src-list="srcList"
            >
            </el-image>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  GetenterPriseInfo,
  enterpriseBase,
  GetPlatFormCustomer,
  GetRoles,
} from "@/api/auditInfo/auditOwner/index";
import { GetGetAppendixList } from "@/api/goodsOwner/goodsOwnerManage/basicInfo";
import UploadImg from "@/components/commonCmpt/cropperImage";
import upLoadAccessory from "@/components/businessCmpt/upLoadAccessory";
export default {
  data() {
    return {
      // 驳回弹窗
      rejectModel: false,
      // 驳回意见
      rejectValue: "",
      // 认证通过备注
      agreeValue: "",
      // 选中客服
      ChineseRadio: "",
      // 搜索客服
      ChineseName: "",
      // 客服列表
      tableData: [],
      // 是否显示客服弹窗
      isDialog: false,
      // 货主信息参数
      basicInfoForm: "",
      // 财务经办人信息
      ebaseInfoForm: "",
      // 货主证件参数
      confirmInfo: "",
      // 货主发票抬头参数
      vehicleForm: "",
      UserID: "",
      // 分页参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 8, //每次请求的数量
        total: 0, //总条数
      },
      // 部门列表
      roleslist: [],
      // 选中的部门
      select: "",
      // 图片放大预览
      srcList: [],
      // 其他证件
      eInfoEx: "",
      // 开户许可证
      accountLicence: {
        loading: false,
        uploaded: false,
        picUrl: "",
        file: "",
      },
      // 其他证件
      uploadInvoiceDialog: false,
      flag: {
        isExchange: false,
        // 是否可操作附件
        isAccessory: false,
      },
      // 附件数据
      pactlist: [],
      // 合同分页参数
      pactData: {
        page: 1, //当前所处的页码
        pagesize: 4, //每次请求的数量
        total: 0, //总条数
      },
      // 附件表格load
      pactLoading: false,
      pageTitle: null,
      isWaitPage: 1,
      // tab状态
      urlType: "",
    };
  },
  components: {
    UploadImg,
    upLoadAccessory,
  },
  activated() {
    this.UserID = this.$route.query.UserID;

    this.pageTitle = this.$route.query.title;
    if (this.$route.query.isWait) this.isWaitPage = +this.$route.query.isWait;

    this.urlType = this.$route.query.urlType; // 客户档案 - 所有代理商需要

    // 查询货主/服务商信息
    this.GetenterPriseInfo(this.$route.query.UserID);
    // 获取客服列表
    this.GetPlatFormCustomer();
    // 获取部门列表
    this.GetRoles();
    // 获取附件
    this.GetGetAppendixList();
  },
  methods: {
    // 返回上一页
    routerBack() {
      if (this.urlType) {
        this.$router.push({
          path: "/platform/alluser/index",
          query: {
            urlType: this.urlType,
          },
        });
        return;
      }
      this.$router.back();
    },
    // 选中部门
    selectlist() {
      let data = {
        ChineseName: this.ChineseName,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        RoleType: this.select,
      };
      this.ChineseRadio = "";
      GetPlatFormCustomer({ Json: JSON.stringify(data) }).then((res) => {
        res.enterpriseBase.forEach((itam, inx, array) => {
          itam.index = inx + 1;
        });
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      });
    },
    // 获取客服列表
    GetRoles() {
      let data = {
        Enabled: 1,
        PageSize: 10000,
        PageIndex: 1,
        appid: "P00001",
      };
      GetRoles(data).then((res) => {
        let arr = [
          {
            ID: 0,
            Name: "全部",
          },
        ];
        this.roleslist = arr.concat(res.data.DataList);
      });
    },
    // 指派客服
    UserByAssign() {
      if (this.ChineseRadio == "") {
        this.$message.error("请选择指派客服.....");
      } else {
        this.refuseClick(1, this.tableData[this.ChineseRadio - 1].UserID);
      }
    },
    // 清除搜索条件
    clearInp() {
      this.pagination.page = 1;
      this.GetPlatFormCustomer();
    },
    // 搜索
    searchClick() {
      this.pagination.page = 1;
      this.GetPlatFormCustomer();
    },
    // 客服列表
    GetPlatFormCustomer() {
      let data = {
        ChineseName: this.ChineseName,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        RoleType: this.select,
      };
      GetPlatFormCustomer({ Json: JSON.stringify(data) }).then((res) => {
        res.enterpriseBase.forEach((itam, inx, array) => {
          itam.index = inx + 1;
        });
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.GetPlatFormCustomer();
    },
    // 拒接通过审核
    refuseClick(num, AssignUserID) {
      if (num == 2) {
        this.$confirm("确定驳回该代理商的审核信息吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.rejectModel = false;
          enterpriseBase({
            UserID: this.UserID,
            Status: num,
            Identity: 2,
            Remark: this.rejectValue,
          }).then((res) => {
            this.isDialog = false;
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          });
        });
      } else {
        enterpriseBase({
          UserID: this.UserID,
          Status: num,
          Identity: 2,
          AssignUserID,
          Remark: this.agreeValue,
        })
          .then((res) => {
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          })
          .finally(() => {
            this.isDialog = false;
          });
      }
    },
    // 查询货主/服务商信息
    GetenterPriseInfo(UserID) {
      GetenterPriseInfo({ UserID }).then((res) => {
        if (res.baseInfo) {
          this.basicInfoForm = res.baseInfo;
          if (res.ebaseInfoEx) this.ebaseInfoForm = res.ebaseInfoEx;
          if (res.baseInfo.Province) {
            this.basicInfoForm.provinces = `${res.baseInfo.Province} / ${res.baseInfo.City} / ${res.baseInfo.County}`;
          }
          if (res.baseInfo.TCKind) {
            this.basicInfoForm.tcDdat = `${res.baseInfo.TCKind} / ${res.baseInfo.TCBig}`;
          }
        }
        if (res.confirmInfo) {
          this.confirmInfo = res.confirmInfo;
          // 用于大图展示
          if (res.confirmInfo.LegalPersonIDCardOnURL) {
            this.srcList.push(res.confirmInfo.LegalPersonIDCardOnURL);
          }
          if (res.confirmInfo.LegalPersonIDCardBackURL) {
            this.srcList.push(res.confirmInfo.LegalPersonIDCardBackURL);
          }
          if (res.confirmInfo.CFOIDCardUrl) {
            this.srcList.push(res.confirmInfo.CFOIDCardUrl);
          }
          if (res.confirmInfo.CFOIDCardBackUrl) {
            this.srcList.push(res.confirmInfo.CFOIDCardBackUrl);
          }
          if (res.confirmInfo.BusinessLicenseURL) {
            this.srcList.push(res.confirmInfo.BusinessLicenseURL);
          }
          if (res.confirmInfo.BankCardUrl) {
            this.srcList.push(res.confirmInfo.BankCardUrl);
          }
          if (res.confirmInfo.OfficialSealUrl) {
            this.srcList.push(res.confirmInfo.OfficialSealUrl);
          }
          if (res.confirmInfo.RTBusinessLicenseURL) {
            this.srcList.push(res.confirmInfo.RTBusinessLicenseURL);
          }
          if (res.confirmInfo.OthersUrl) {
            this.srcList.push(res.confirmInfo.OthersUrl);
          }
        }
        if (res.titleInfo) {
          this.vehicleForm = res.titleInfo;
        }
        if (res.eInfoEx[0].ImageURL) {
          res.eInfoEx.forEach((item) => {
            this.srcList.push(item.ImageURL);
          });
          this.eInfoEx = res.eInfoEx;
        }
      });
    },

    // 获取附件
    GetGetAppendixList() {
      let data = {
        PageIndex: this.pactData.page,
        PageSize: this.pactData.pagesize,
        UserID: this.UserID,
      };
      GetGetAppendixList({ Json: JSON.stringify(data) }).then((res) => {
        this.pactData.total = res.data.TotalCount;
        this.pactlist = res.data.DataList;
      });
    },

    // 附件分页
    pactChange(e) {
      this.pactData.page = e;
      // 获取附件
      this.GetGetAppendixList();
    },
  },
};
</script>

<style lang="scss">
.wrap {
  .el-input.is-disabled .el-input__inner {
    color: #333 !important;
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.img {
  width: 288px;
  height: 178px;
  border-radius: 10px;
}

.topWrap {
  display: flex;
  justify-content: space-between;

  .titles {
    font-size: 20px;
    padding: 4px 0 0 0;
  }
}

.content,
.content1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .upBox {
    text-align: center;
  }
}

.upText {
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
  color: #333333;
}

.content1 {
  justify-content: space-evenly;
}

.title {
  color: $primary;
  position: relative;
  text-indent: 15px;
  font-size: 14px;
  border-bottom: 1px solid $borderGray;
  padding: 20px 0;
  margin-bottom: 20px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    border-radius: 5px;
    background: $primary;
  }
}

.accessory {
  width: 100%;
  text-align: center;
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>
